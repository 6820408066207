<template>
  <div>
    <div class="cardTitle">巡检记录</div>
    <searchForm
      :formItem="formItem"
      @getSearch="search($event)"
    ></searchForm>
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
	   :scroll="{ x: 2600 }"
      @change="handleTableChange"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: selectionChoosed,
      }"
      :row-key="
        (record, index) => {
          return record.id;
        }
      "
    >
    </a-table>
  </div>
</template>

<script>
import { inspectionExecute } from "@/api/operation/inspection/plan.js";
import { formItem, columns, pagination } from "./depend/config";
export default {
	data() {
		return {
			drawer: {
				show: false,
				detail: false,
			},
			editId: null,
			detailId: null,
			tableData: [],
			searchForm: {
				code: undefined,
				inspectorTel: undefined,
				inspectionStatus: undefined,
			},
			formItem,
			columns,
			pagination,
			// 选择的index
			selectedRowKeys: [],
		};
	},
	mounted() {
		this.getData();
	},
	methods: {
		search(data){
			this.searchForm = data;
			this.getData()
		},
		async getData() {
			let res = await inspectionExecute({
				pageNum: this.pagination.current,
				size: this.pagination.pageSize,
				...this.searchForm,
			});
			this.tableData = res.data.rows;
			this.pagination.total = res.data.total;
			this.selectedRowKeys = [];
		},
		selectionChoosed(data) {
			this.selectedRowKeys = data;
		},
		handleTableChange(pagination) {
			const pager = { ...this.pagination };
			pager.current = pagination.current;
			pager.pageSize = pagination.pageSize;
			this.pagination = pager;
			this.getData();
		},
	},
	computed: {
		hasSelected() {
			return this.selectedRowKeys.length > 0;
		},
	},
};
</script>
<style lang="less" scoped>
.table-img {
  width: 40px;
  height: 40px;
}
/deep/ #qrCodeUrl > img {
        margin: 0 auto;
    }
</style>
