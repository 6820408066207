import httpService from "@/request"

export function planList(params) {
	return httpService({
		url: `/user/inspectionPlan/list`,
		method: 'get',
		params: params,
	})
}
export function planisEnable(params) {
	return httpService({
		url: `/user/inspectionPlan/isEnable`,
		method: 'get',
		params: params,
	})
}
export function routeSelect(params) {
	return httpService({
		url: `/user/inspectionRoute/allList`,
		method: 'get',
		params: params,
	})
}
export function planInfo(params) {
	return httpService({
		url: `/user/inspectionPlan/findById`,
		method: 'get',
		params: params,
	})
}
export function planInsert(params) {
	return httpService({
		url: `/user/inspectionPlan/insert`,
		method: 'post',
		data: params,
	})
}
export function planDel(params) {
	return httpService({
		url: `/user/inspectionPlan/delete`,
		method: 'post',
		data: params,
	})
}
export function planUpdate(params) {
	return httpService({
		url: `/user/inspectionPlan/update`,
		method: 'post',
		data: params,
	})
}
// 巡检记录
export function inspectionExecute(params) {
	return httpService({
		url: `/user/inspectionExecute/list`,
		method: 'get',
		params: params,
	})
}
