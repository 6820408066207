export const formItem = [
	{
		type: 'input',
		label:'编号',
		prop:'code',
		placeholder:'请输入'
	},
	{
		type: 'input',
		label:'巡检人手机号',
		prop:'inspectorTel',
		placeholder:'请输入'
	},
	{
		type: 'select',
		label:'巡检状态',
		prop:'inspectionStatus',
		placeholder: '请选择',
		option:[{ id:1,name:'待巡检'},{ id:2,name:'已巡检'},{ id:3,name:'巡检中'},{ id:4,name:'未巡检'}]
	},
]
export const columns = [
	
	{
		title: "执行巡检编号",
		dataIndex: "code",
	},
	{
		title: "执行巡检名称",
		dataIndex: "name",
	},
	{
		title: "计划当次巡检开始时间",
		dataIndex: "beginDate",
	},
	{
		title: "计划当次巡检结束时间",
		dataIndex: "endDate",
	},
	{
		title: "实际当次巡检开始时间",
		dataIndex: "actualBeginDate",
	},
	{
		title: "实际当次巡检结束时间",
		dataIndex: "actualEndDate",
	},
	{
		title: "状态",
		dataIndex: "status",
		customRender: function (status) {
			switch (status) {
			case 1:
				return "待巡检"
				break;
			case 2:
				return "已巡检"
				break;
			case 3:
				return "巡检中"
				break;
			case 4:
				return "未巡检"
				break;
			default:
				break;
			}
		}
	},
	{
		title: "巡检人姓名",
		dataIndex: "inspectorName",
	},
	{
		title: "巡检人手机号",
		dataIndex: "inspectorTel",
	},
]
export const pagination = {
	current: 1,
	total: 0,
	pageSize: 10,
	showTotal: (total) => `共 ${total} 条`,
	showSizeChanger: true,
	showQuickJumper: true,
}